// __<<ngThemingMigrationEscapedComment0>>__
// @import '@angular/cdk/overlay-prebuilt';
// @import '@angular/cdk/a11y';
// @import 'node_modules/@angular/cdk/_a11y.scss';
// @import '../../../node_modules/@angular/cdk/ally';
// @import '@angular/cdk/text-field';
// @import '@angular/material/theming';
// __<<ngThemingMigrationEscapedComment1>>__
// @import '../../../node_modules/basscss/css/basscss.min';

// @import 'app/components-library/core/core';
// @import 'app/components-library/core/theming/palette';
// @import 'app/components-library/core/theming/theming';
// @import 'app/components-library/core/theming/all-theme';
// @import 'app/components-library/wis-menu/menu-theme';
// @import 'app/color';
// @import 'styles/material/checkbox';
// @import 'styles/expansion-panel';
// @import 'styles/wis-step';
// @import 'styles/wis-arrow';
// @import 'styles/mat-form-field';
// @import 'styles/mat-select';
// @import 'styles/material-styles';
// @import './app/_utilities';

// @import 'assets/fonts/Montserrat.css';
// @import 'assets/fonts/TTCommons.css';
// @import 'assets/fonts/Roboto.css';
// @import 'assets/fonts/FontAwesome5Proregular.css';
// @import 'assets/fonts/materialIcons.css';

// @include cdk-a11y();
// @include cdk-text-field();
// @include wis-core();

// $exp-primary: wis-palette($wis-gray);
// $exp-accent: wis-palette($wis-palette);
// $wis-theme: wis-light-theme($exp-primary, $exp-accent);

// @include wis-menu-theme();

// @include wis-core-theme($wis-theme);

// @include mat-core();
// @include mat-autocomplete-theme($wis-theme);
// @include mat-checkbox-theme($wis-theme);
// @include mat-radio-theme($wis-theme);
// @include mat-select-theme($wis-theme);
// @include mat-form-field-theme($wis-theme);
// @include mat-tree-theme($wis-theme);
// @include mat-table-theme($wis-theme);
// @include mat-slide-toggle-theme($wis-theme);
// @include mat-datepicker-theme($wis-theme);
// @include mat-chips-theme($wis-theme);
// @include mat-list-theme($wis-theme);
$error: #e6614f;
// @import '../../../libs/components/src/lib/variables';

@import 'basscss-align';
@import 'basscss-border';
@import 'basscss-flexbox';
@import 'basscss-grid/css/grid';
@import 'basscss-hide';
@import 'basscss-layout';
@import 'basscss-margin';
@import 'basscss-padding';
@import 'basscss-position';
@import 'basscss-type-scale';
@import 'basscss-typography';

*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
.mat-expansion-panel .mat-expansion-panel-content {
  font-size: 100%;
}
body,
.mat-expansion-panel .mat-expansion-panel-content {
  font-size: 1rem;
}
html,
body,
.mat-expansion-panel .mat-expansion-panel-content,
.mat-expansion-panel .mat-expansion-panel-header {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.mat-expansion-panel .mat-expansion-panel-content {
  line-height: normal;
}

img {
  max-width: 100%;
  display: block;
}
svg {
  max-height: 100%;
  display: block;
}
.full-width {
  width: 100%;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
}
:disabled,
[disabled],
[aria-disabled='true'] {
  cursor: not-allowed;
}
button {
  background: transparent;
  overflow: visible;
}
textarea {
  overflow-y: auto;
  background: transparent;
}
input,
textarea,
select,
button {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: 'Montserrat', sans-serif;
}
[type='reset'] {
  cursor: pointer;
}

@media print {
  .hidden-print {
    display: none;
  }
}

.sub-header {
  margin-top: 0 !important;
}

._field-has-errors {
  .exp-select__top {
    border: 2px solid $error;

    &:hover {
      border: 2px solid $error;
    }
  }
}

.error-red {
  border: 2px solid $error !important;
}

body {
  // material global overrides, kept for backward compatability
  // todo:: move this to client applications

  .mat-radio-container {
    width: 1rem;
    height: 1rem;
  }

  .mat-radio-label {
    font-size: 14px;
    color: #2e2f2f;
  }

  .mat-radio-outer-circle {
    border-width: 1px;
    width: 1rem;
    height: 1rem;
  }

  .mat-radio-inner-circle {
    width: 1rem;
    height: 1rem;
  }
  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.625);
    // @include cdk-high-contrast {
    //   border: solid 0.625rem;
    // }
  }

  .mat-radio-label-content {
    padding-left: 8px;
    [dir='rtl'] & {
      padding-right: 8px;
    }
  }
  .mat-radio-label-content.mat-radio-label-before {
    padding-right: 8px;
    [dir='rtl'] & {
      padding-left: 8px;
    }
  }
  .mat-radio-button .mat-radio-ripple {
    display: none;
  }
}

// todo:: move this to client applications
mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2ab5ca;
}

mat-radio-button.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #2ab5ca;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2ab5ca;
}

._dropdown-autocomplete {
  .exp-form-field-flex:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 16px;
    top: -4px;
    bottom: 0;
    margin: auto;
    transform: rotate(-135deg);
    border-top: 1px solid #95a2ab;
    border-left: 1px solid #95a2ab;
    pointer-events: none;
    touch-action: none;
  }
}
._exp-autocomplete-dropdown-mode {
  mat-option {
    &.mat-active {
      background: initial;
      color: rgba(0, 0, 0, 0.87);
      .mat-option-text {
        color: rgba(0, 0, 0, 0.87);
      }
    }
    &:focus {
      background: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.87);
      .mat-option-text {
        color: #35a7ff;
      }
    }
  }
  .mat-autocomplete-panel.mat-autocomplete-hidden {
    visibility: visible;
    padding: 0 8px;
  }
}
